
import React, { useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";
import NewsItems from "./NewsItems.tsx";
import "./pagination.css";

interface PageChangeEvent {
  selected: number;
}
type PropsResponGetData = {
  data?: any | null;
  loading?: boolean;
  error?: Error | null;
  search?: (queryString: string) => void;
  loadMore?: (page: number) => void;
  reLoad?: () => void;
  totalCount?: any;
};

const ListNews = () => {
  const [searchValue, setSearchValue] = useState("");

  const { data, search, loadMore, totalCount }: PropsResponGetData =
    GetList<any>({
      url: "news/get-list-news",
      params: {},
    });

  const [isLoading, setisLoading] = useState(false);
  const handlePageChange = ({ selected }: PageChangeEvent) => {
    loadMore(selected + 1);
  };
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (buttonRef.current) buttonRef.current.focus();
    }
  };
  return (
    <LoadingWrap active={isLoading}>
      {data?.length > 0 ? (
        <div>
          <div className="flex justify-center">
            <div
              className="
              flex 
              items-center 
              border-[1.5px] 
              w-[100%]
              md:w-[60%]
              rounded-lg
              pl-2
              border-primary
              "
            >
              <input
                className="flex min-w-[100px] px-2 py-2 outline-none w-full"
                placeholder="Tìm kiếm sự kiện"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onKeyDown={handleKeyPress}
              />
              <button
                onClick={() => search(searchValue)}
                className="h-full px-3 rounded-tr-lg rounded-br-lg bg-primary"
                ref={buttonRef}
              >
                <AiOutlineSearch size={26} />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {data?.length > 0 &&
              data?.map((item: any, index: number) => {
                return <NewsItems index={index} item={item} key={index} />;
              })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 20,
              boxSizing: "border-box",
              width: "100%",
              height: "100%",
            }}
          >
            <ReactPaginate
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              breakLabel={"..."}
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              marginPagesDisplayed={2}
              nextClassName={"item next "}
              nextLabel={">"}
              onPageChange={handlePageChange}
              pageCount={totalCount / 20}
              pageClassName={"item pagination-page "}
              pageRangeDisplayed={2}
              previousClassName={"item previous"}
              previousLabel={"<"}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-xl font-bold text-center text-gray-400">
            Danh sách trống
          </h1>
        </div>
      )}
      <ToastContainer />
    </LoadingWrap>
  );
};

export default ListNews;
