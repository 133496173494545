import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";

interface IFormInput {
  title: string;
  description: string;
  amount: number | null;
}

const validationSchemaRegister = Yup.object().shape({
  title: Yup.string().required("Vui lòng nhập tiêu đề"),
  description: Yup.string().required("Vui lòng nhập mô tả"),
  amount: Yup.number().required("Vui lòng nhập số tiền"),
});

const AddFund = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      title: "",
      description: "",
      amount: null,
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };

  const notify = (title: string) => toast(title);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    const body = {
      title: data.title,
      description: data.description,
      amount: data.amount,
    };
    const res = await request(
      `fund/create-fund`,
      body,
      "POST"
    );
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    setLoading(false);
  };

  const classInput = "w-full border-[1px] rounded-md px-2 py-2 outline-none";
  const classLable = "font-semibold";

  return (
    <LoadingWrap active={loading}>
      <div className="w-full">
        <div className="text-2xl font-bold text-center text-primary">
          {"TIỀN TÀI TRỢ"}
        </div>
        <div className="mb-3">
          <div className={classLable}>Tên người tài trợ</div>
          <input
            onChange={(value) => handelOnchange(value, "title")}
            placeholder="Nhập tên người tài trợ"
            className={classInput}
          />
          <div>
            {errors?.title ? (
              <text style={{ color: "red" }}>{errors.title?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <div className={classLable}>Số tiền</div>
          <input
            onChange={(value) => handelOnchange(value, "amount")}
            placeholder="Nhập số tiền"
            className={classInput}
            type="number"
          />
          <div>
            {errors?.amount ? (
              <text style={{ color: "red" }}>{errors.amount?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <div className={classLable}>Mô tả</div>
          <input
            onChange={(value) => handelOnchange(value, "description")}
            placeholder="Nhập mô tả"
            className={classInput}
          />
          <div>
            {errors?.description ? (
              <text style={{ color: "red" }}>{errors.description?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            className="self-center px-8 py-2 mb-20 text-white rounded-md bg-primary"
          >
            {"Nhập tiền tài trợ"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default AddFund;
