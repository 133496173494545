import React from "react";
import { AiFillCloseCircle, AiFillEnvironment } from "react-icons/ai";
import {
  FaCashRegister,
  FaCcDinersClub,
  FaChartLine,
  FaCodeBranch,
  FaGift,
  FaHistory,
  FaLayerGroup,
  FaRegImages,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index.tsx";
import {
  authSelect,
  setIsLogin,
  setToken,
  setUserInfo,
} from "../../store/slice/auth.slice.tsx";
const dataRouteAdmin = [
  {
    lable: "Thống Kê",
    id: 1,
    route: [
      { path: "/dashboard", lable: "Thống kê", id: 1, icon: <FaChartLine /> },
    ],
  },
  {
    lable: "Người dùng",
    id: 2,
    route: [
      { path: "/admin/customer", lable: "Người dùng", id: 2, icon: <FaUser /> },
    ],
  },
  {
    lable: "CLB",
    id: 1,
    route: [
      { path: "/admin/list-club", lable: "Danh sách CLB", id: 1, icon: <FaLayerGroup /> },
      { path: "/admin/add-club", lable: "Thêm CLB", id: 2, icon: <FaLayerGroup /> },
    ],
  },

  {
    lable: "Tin tức",
    id: 3,
    route: [
      {
        path: "/admin/list-news",
        lable: "Danh sách tin tức",
        id: 3,
        icon: <FaCodeBranch />,
      },
      {
        path: "/admin/add-news",
        lable: "Thêm tin tức",
        id: 4,
        icon: <FaCodeBranch />,
      },
    ],
  },

  {
    lable: "Sự kiện",
    id: 5,
    route: [
      {
        path: "/admin/list-event",
        lable: "Danh sách sự kiện",
        id: 9,
        icon: <AiFillEnvironment />,
      },
      {
        path: "/admin/add-event",
        lable: "Thêm sự kiện",
        id: 10,
        icon: <AiFillEnvironment />,
      },
    ],
  },

  {
    lable: "Điều lệ",
    id: 3,
    route: [
      {
        path: "/admin/club-rule",
        lable: "Điều lệ CLB",
        id: 3,
        icon: <FaCcDinersClub />,
      },
      {
        path: "/admin/golf-law",
        lable: "Luật Golf",
        id: 4,
        icon: <FaCodeBranch />,
      },

      {
        path: "/admin/membership-benefits",
        lable: "Quyền lợi hội viên",
        id: 4,
        icon: <FaGift />,
      },

      {
        path: "/admin/lich-su-clb",
        lable: "Lịch sử CLB",
        id: 4,
        icon: <FaHistory />,
      },
    ],
  },

  {
    lable: "Quỹ CLB",
    id: 3,
    route: [
      {
        path: "/admin/list-payment",
        lable: "Tổng thu",
        id: 3,
        icon: <FaCashRegister />,
      },
      {
        path: "/admin/tong-chi",
        lable: "Tổng chi",
        id: 4,
        icon: <FaCashRegister />,
      },
      {
        path: "/admin/Add-fund",
        lable: "Tiền tài trợ",
        id: 4,
        icon: <FaCashRegister />,
      },
    ],
  },
];

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}
export default function MobileNavBar({ isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(authSelect);
  const dataNav = dataRouteAdmin; // nhưng thứ sẽ hiện ra ở thanh navBar
  const navAnimation = isOpen ? "translate-x-0" : "translate-x-[-100%]";
  return (
    <div
      className={`fixed ${navAnimation} h-full min-h-screen duration-300 top-0 left-0 right-0 bottom-0 z-[10000] bg-primary overflow-y-auto`}
    >
      <div className="py-4">
        <div className="flex items-center justify-center">
          {/* <img
            src={images.logo}
            alt="Logo"
            className="w-[70px] h-[70px] object-contain"
          /> */}
         <div className="ml-2 text-2xl font-bold text-white">GOLF - CLUB</div>
        </div>
        <AiFillCloseCircle
          onClick={() => setIsOpen(false)}
          className="w-[30px] h-[30px] absolute right-2 top-2 cursor-pointer text-white"
        />
        <div className="mt-5">
          {dataNav.map((item, index) => {
            return (
              <div className="px-5 py-0 rounded-xl" key={index + "navRoute"}>
                {item.route.length > 1 && (
                  <div className="mt-5 font-bold">{item.lable}</div>
                )}
                <div>
                  {item.route.map((route, subindex) => {
                    const Icon = route.icon;

                    return (
                      <div
                        onClick={() => {
                          setIsOpen(false);
                          navigate(route.path);
                        }}
                        className={`bg-[white] 
                        rounded-xl
                        px-2 
                        mt-1 
                        hover:bg-pink-500
                        cursor-pointer
                        flex
                        items-center
                        min-h-[40px]
                        `}
                        key={subindex}
                      >
                        <div>{route.icon}</div>
                        <div className="ml-2">{route.lable}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center my-5">
          <button
            onClick={() => {
              dispatch(setIsLogin(false));
              dispatch(setToken(""));
              dispatch(setUserInfo({}));
              navigate("/")
            }}
            className="flex items-center self-center text-xl text-white"
          >
            Đăng xuất
            <FaSignOutAlt className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
}
