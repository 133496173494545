import { useState } from "react";
// import "react-quill/dist/quill.snow.css";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import DateTimePicker from "react-datetime-picker";
import { images } from "../../../assets/index.tsx";
import { Dropdown } from "../../../component/Dropdow/index.tsx";
import Switch from "react-switch";

const validationSchemaRegister = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền tên người dùng"),
  email: Yup.string().required("Vui lòng điền email"),
});
interface InputForm {
  name: string;
  birthDay?: string;
  size_uniform?: string;
  email: string;
  phoneNumber?: string;
  address?: string;
  business_areas?: string;
  avatar?: string;
  isBlock?: boolean;
  vga?: string;
  hdc?:string;
  isConfirm?: boolean;
}

const EditUser = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { item } = location.state || {}; // Truy cập vào 'id' từ 'state', mặc định là trả về undefined nếu không có state
  const navigate = useNavigate();
  const [isToggle, setisToggle] = useState(item?.can_read_statistic);
  const [birthDay, setbirthDay] = useState<Date | null>(
    new Date(item?.birthDay)
  );
  const [role, setrole] = useState(item?.role);

  const dataRole = [
    { lable: "Người dùng", value: "user" },
    { lable: "Admin", value: "ADMIN" },
  ];

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<InputForm>({
    defaultValues: {
      name: item?.name || "",
      birthDay: item?.birthDay || "",
      email: item?.email || "",
      phoneNumber: item?.phone || "",
      address: item?.address || "",
      business_areas: item?.business_areas || "",
      avatar: item?.avatar || "",
      size_uniform: item?.size_uniform || "",
      vga: item?.vga || "",
      hdc: item?.hdc || "",
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };

  const notify = (title: string) => toast(title);

  const onSubmit = async (_data: any) => {
    setLoading(true);
    const body = {
      name: _data.name,
      email: _data.email,
      vga: _data.vga,
      birdthDay: birthDay,
      userId: item?.id,
      phone: _data.phoneNumber,
      address: _data?.address,
      business_areas: _data?.business_areas,
      size_uniform: _data?.size_uniform,
      role: role,
      hdc: _data?.hdc,
    };
    const res = await request("users/admin-updateUser", body, "POST");
    if (res?.status) {
      navigate(-1);
    }
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");

    setLoading(false);
  };

  const onChangePermission = async () => {
    const response = await request(
      "users/add-statistic-permission",
      { userId: item?.id },
      "POST"
    );
  };

  const onDelete = async () => {
    setLoading(true)
    const response = await request(
      `users/delete-user?userId=${item?.id}`,
      {},
      "POST"
    );
    if(response.status){
      navigate(-1)
    } 
    else {
      notify("Đã có lỗi sảy ra, vui lòng thử lại sau!")
    }
    setLoading(false)
  };

  return (
    <LoadingWrap active={loading}>
      <div className="w-full">
        <div className="flex flex-col items-center justify-center text-center">
          <strong className="text-2xl text-center text-primary">
            Thông tin người dùng
          </strong>

          <img
            src={item.avatar || images.ic_user}
            className="w-[200px] h-[200px] rounded-full my-10"
          />
        </div>
        <div className="flex-col w-full my-4">
          <div className="font-bold">Tên người dùng</div>
          <input
            onChange={(value) => handelOnchange(value, "name")}
            placeholder="Nhập tên người dùng"
            defaultValue={getValues("name")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
          <div>
            {errors?.name ? (
              <div style={{ color: "red" }}>{errors.name?.message}</div>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <p className={"font-bold"}>Sinh nhật</p>
          <DateTimePicker onChange={setbirthDay} value={birthDay} />
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">Email</div>
          <input
            onChange={(value) => handelOnchange(value, "email")}
            placeholder="Email"
            className="border-[1px] rounded-lg p-2 outline-none w-full"
            defaultValue={getValues("email")}
          />
          <div>
            {errors?.email ? (
              <text style={{ color: "red" }}>{errors.email?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">Số điện thoại</div>
          <input
            placeholder="Số điện thoại"
            defaultValue={getValues("phoneNumber")}
            onChange={(value) => handelOnchange(value, "phoneNumber")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">Size áo</div>
          <input
            placeholder="Nhập size áo"
            defaultValue={getValues("size_uniform")}
            onChange={(txt) => handelOnchange(txt, "size_uniform")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">Lĩnh vực kinh doanh</div>
          <input
            placeholder="Nhập lĩnh vực kinh doanh"
            defaultValue={getValues("business_areas")}
            onChange={(txt) => handelOnchange(txt, "business_areas")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">VGA</div>
          <input
            placeholder="Nhập VGA"
            defaultValue={getValues("vga")}
            onChange={(txt) => handelOnchange(txt, "vga")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4">
          <div className="font-bold">HDC</div>
          <input
            placeholder="Nhập HDC"
            defaultValue={getValues("hdc")}
            onChange={(txt) => handelOnchange(txt, "hdc")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4 ">
          <div className="font-bold">Địa chỉ</div>
          <input
            placeholder="Nhập địa chỉ"
            defaultValue={getValues("address")}
            onChange={(txt) => handelOnchange(txt, "address")}
            className="border-[1px] rounded-lg p-2 outline-none w-full"
          />
        </div>

        <div className="flex-col w-full my-4 ">
          <div className="font-bold">Phân quyền</div>
          <Dropdown
            placeholder="Chọn loại phân quyền"
            value={role}
            data={dataRole}
            filedKey={"lable"}
            onSelectItem={(selected) => {
              setrole(selected?.value);
            }}
          />
        </div>

        <div>
          <p className="font-bold">Cho phép xem thống kê quỹ CLB</p>
          <Switch
            onChange={(check: boolean) => {
              onChangePermission();
              setisToggle(check);
            }}
            checked={isToggle}
          />
        </div>
      </div>
      <div className="flex justify-center gap-3 mt-10">
        <button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          className="px-10 py-3 text-white rounded-md bg-primary"
        >
          Chỉnh sửa
        </button>

        <button
          onClick={onDelete}
          color="primary"
          className="px-10 py-3 text-white bg-red-500 rounded-md"
        >
          Xoá
        </button>
      </div>

      <ToastContainer />
    </LoadingWrap>
  );
};

export default EditUser;
