import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL, request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";

interface IFormInput {
  name: string;
  website_url: string;
}

const validationSchemaRegister = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên CLB"),
  website_url: Yup.string().required("Vui lòng nhập link website"),
});

const AddClub = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const navigate = useNavigate();
  const { item } = location.state || {};
  useEffect(() => {
    if (item) {
      setisUpdate(true);
      setSelectedImage(item?.image);
    }
  }, [item]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: item?.name || "",
      website_url: item?.website_url,
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };

  const notify = (title: string) => toast(title);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    if (selectedImage) {
      if (isUpdate) {
        onUpdate(data);
      } else {
        const body = {
          name: data.name,
          website_url: data.website_url,
          image: selectedImage,
        };
        const res = await request("club/create-club", body, "POST");
        notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
        setTimeout(() => {
          if (res?.status) {
            // navigate(-1);
            navigate("/admin/list-club");
          }
        }, 500);
      }
    } else {
      notify("Vui lòng chọn hình ảnh");
    }

    setLoading(false);
  };

  const onUpdate = async (data: any) => {
    setLoading(true);
    const body = {
      name: data.name,
      website_url: data.website_url,
      image: selectedImage,
    };
    const res = await request(
      `club/update-club?id=${item.id}`,
      body,
      "POST"
    );
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    setLoading(false);
    setTimeout(() => {
      if (res?.status) {
        navigate(-1);
      }
    }, 500);
  };

  const handleImageChange = async (event: any) => {
    setLoading(true);
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    try {
      const res = await fetch(BASE_URL + "upload/upload", {
        method: "POST",
        body: formData,
      });
      const resJson = await res.json();
      if (resJson?.status) {
        setSelectedImage(resJson?.data);
      } else {
        notify(resJson?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
      }
    } catch (e) {
      notify("Đã có lỗi xảy ra, vui lòng thử lại sau");
    }
    setLoading(false);
  };

  const onDelete = () => {
    setLoading(true);
    request(`club/delete-club?id=${item.id}`, {}, "POST").then(
      (response) => {
        notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
        setLoading(false);
        if (response?.status) {
          navigate(-1);
        }
      }
    );
  };

  const classInput = "w-full border-[1px] rounded-md px-2 py-2 outline-none";
  const classLable = "font-semibold";

  return (
    <LoadingWrap active={loading}>
      <div className="w-full">
        <div className="text-2xl font-bold text-center text-primary">
          {isUpdate ? "CẬP NHẬT CLB" : "THÊM CLB"}
        </div>
        <div className="mb-3">
          <div className={classLable}>Tên CLB</div>
          <input
            onChange={(value) => handelOnchange(value, "name")}
            placeholder="Nhập tên CLB"
            className={classInput}
            defaultValue={getValues("name")}
          />
          <div>
            {errors?.name ? (
              <text style={{ color: "red" }}>{errors.name?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <div className={classLable}>Link website</div>
          <input
            onChange={(value) => handelOnchange(value, "website_url")}
            placeholder="Nhập link website của CLB"
            className={classInput}
            defaultValue={getValues("website_url")}
          />
          <div>
            {errors?.website_url ? (
              <text style={{ color: "red" }}>{errors.website_url?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          {selectedImage && (
            <div>
              <h2>Ảnh đã chọn:</h2>
              <img
                src={selectedImage}
                alt="Selected"
                style={{ maxWidth: "15%" }}
              />
            </div>
          )}
          <div className={classLable}>Ảnh CLB</div>
          <input
            onChange={handleImageChange}
            type="file"
            className={classInput}
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            className="self-center px-8 py-2 mb-20 text-white rounded-md bg-primary"
          >
            {isUpdate ? "Cập nhật" : "Tạo mới"}
          </button>

          {isUpdate && (
            <button
              onClick={onDelete}
              color="primary"
              className="self-center px-8 py-2 mb-20 ml-2 text-white bg-red-500 rounded-md"
            >
              {"Xoá"}
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default AddClub;
