import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import * as Yup from "yup";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import React from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "../../../component/Dropdow/index.tsx";
import { GetList } from "../../../hook/getList.tsx";

interface IFormInput {
  name: string;
  description: string;
  address: string;
  startDate?: Date;
  endDate?: Date;
  fee_ko_hv: number;
  fee_hv: number;
  people_size: number;
  status: number | null;
}

interface DropDownProps {
  lable: string;
  value: number;
}

const validationSchemaRegister = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền tên sự kiện"),
  description: Yup.string().required("Vui lòng nhập mô tả cửa hàng"),
  address: Yup.string().required("Vui lòng nhập địa chỉ"),
  fee_ko_hv: Yup.number().required(
    "Vui lòng nhập lệ phí nếu không phải là hội viên"
  ),
  fee_hv: Yup.number().required("Vui lòng nhập lệ phí nếu là hội viên"),
  people_size: Yup.number().required(
    "Vui lòng nhập số lượng người tham gia tối đa"
  ),
  status: Yup.number().required("Vui lòng chọn trạng thái sự kiện"),
});

const AddEvent = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [timeStart, setTimeStart] = useState<any>(new Date());
  const [timeEnd, setTimeEnd] = useState<any>(new Date());
  const location = useLocation();

  const navigate = useNavigate();

  const [statusSelected, setstatusSelected] = useState("Sắp diễn ra");
  const { item } = location.state || {};
  const { data: detailNews } = GetList({
    url: `event/get-event-detail?event_id=${item?.id}`,
    isLazy: false,
  });
  useEffect(() => {
    if (item) {
      setisUpdate(true);
      setTimeEnd(item?.startDate);
      setTimeStart(item?.endDate);
    }
  }, [item]);

  useEffect(() => {
    if (detailNews) {
      setValue("description", detailNews?.description);
    }
  }, [detailNews]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: item?.title || "",
      description: item?.description?.replace(/&lt;/g, "<") || "",
      address: item?.address || "",
      startDate: item?.startDate || new Date(),
      endDate: item?.endDate || new Date(),
      fee_ko_hv: item?.fee_ko_hv || 0,
      fee_hv: item?.fee_hv || 0,
      people_size: item?.people_size,
      status: item?.status || 1,
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };
  const MyEditormodules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const MyEditorformats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const notify = (title: string) => toast(title);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    if (isUpdate) {
      onUpdate(data);
    } else {
      const body = {
        title: data.name,
        description: data.description,
        startDate: timeStart,
        endDate: timeEnd,
        fee_ko_hv: data.fee_ko_hv,
        fee_hv: data.fee_hv,
        people_size: data.people_size,
        status: data.status,
        address: data.address,
      };
      const res = await request("event/create-event", body, "POST");
      console.log("=====res====", res);

      if (res?.status) {
        navigate("/admin/list-event");
      }
      notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    }

    setLoading(false);
  };

  const onUpdate = async (data: any) => {
    setLoading(true);
    const body = {
      title: data.name,
      description: data.description,
      startDate: timeStart,
      endDate: timeEnd,
      fee_ko_hv: data.fee_ko_hv,
      fee_hv: data.fee_hv,
      people_size: data.people_size,
      status: data.status,
      address: data.address,
    };
    const res = await request(
      `event/update-event?event_id=${item.id}`,
      body,
      "POST"
    );
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    setLoading(false);
  };

  const classInput = "w-full border-[1px] rounded-md px-2 py-2 outline-none";
  const classLable = "font-semibold";

  const dataStatus = [
    { lable: "Sắp diễn ra", value: 1 },
    { lable: "Đang diễn ra", value: 2 },
    { lable: "Đã kết thúc", value: 3 },
  ];

  if (!detailNews && isUpdate) {
    <div className="flex h-screen">
      <LoadingWrap active={true}>
        <div></div>
      </LoadingWrap>
      ;
    </div>;
  } else
    return (
      <LoadingWrap active={loading}>
        
        <div className="w-full">
          <div className="text-2xl font-bold text-center text-primary">
            {isUpdate ? "CẬP NHẬT SỰ KIỆN" : "THÊM SỰ KIỆN"}
          </div>
          <div className="mb-3">
            <div className={classLable}>Tên sự kiện</div>
            <input
              onChange={(value) => handelOnchange(value, "name")}
              placeholder="Nhập tên sự kiện"
              className={classInput}
              defaultValue={getValues("name")}
            />
            <div>
              {errors?.name ? (
                <text style={{ color: "red" }}>{errors.name?.message}</text>
              ) : null}
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-full mr-5">
              <div className={classLable}>Số lượng người tham gia</div>
              <input
                onChange={(value) => handelOnchange(value, "people_size")}
                placeholder="Nhập số lượng người tham dự"
                className={classInput}
                defaultValue={getValues("people_size")}
                type="number"
              />
              <div>
                {errors?.people_size ? (
                  <text style={{ color: "red" }}>
                    {errors.people_size?.message}
                  </text>
                ) : (
                  <text className="h-2 bg-red-200 opacity-0">-</text>
                )}
              </div>
            </div>

            <div className="w-full">
              <Dropdown
                data={dataStatus}
                filedKey={"lable"}
                onSelectItem={(item: DropDownProps) => {
                  setValue("status", item.value);
                  setstatusSelected(item.lable);
                }}
                value={statusSelected}
                placeholder="Chọn trạng thái"
                lable="Chọn trạng thái"
              />
              <div>
                {errors?.status ? (
                  <text style={{ color: "red" }}>{errors.status?.message}</text>
                ) : (
                  <text className="h-2 bg-red-200 opacity-0">-</text>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center my-5">
            <div className="w-full mr-5">
              <div className={classLable}>Lệ phí đối với hội viên</div>
              <input
                onChange={(value) => handelOnchange(value, "fee_hv")}
                placeholder="Nhập lệ phí"
                className={classInput}
                defaultValue={getValues("fee_hv")}
                type="number"
              />
              <div>
                {errors?.fee_hv ? (
                  <text style={{ color: "red" }}>{errors.fee_hv?.message}</text>
                ) : (
                  <text className="h-2 bg-red-200 opacity-0">-</text>
                )}
              </div>
            </div>

            <div className="w-full mr-5">
              <div className={classLable}>
                Lệ phí đối với người không phải hội viên
              </div>
              <input
                onChange={(value) => handelOnchange(value, "fee_ko_hv")}
                placeholder="Nhập lệ phí"
                className={classInput}
                defaultValue={getValues("fee_ko_hv")}
                type="number"
              />
              <div>
                {errors?.fee_ko_hv ? (
                  <text style={{ color: "red" }}>
                    {errors.fee_ko_hv?.message}
                  </text>
                ) : (
                  <text className="h-2 bg-red-200 opacity-0">-</text>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className={classLable}>Địa chỉ</div>
            <input
              onChange={(value) => handelOnchange(value, "address")}
              placeholder="Nhập địa chỉ"
              className={classInput}
              defaultValue={getValues("address")}
            />
            <div>
              {errors?.address ? (
                <text style={{ color: "red" }}>{errors.address?.message}</text>
              ) : (
                <text className="h-2 bg-red-200 opacity-0">-</text>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="mb-3">
              <p className={classLable}>Ngày bắt đầu</p>
              <DateTimePicker onChange={setTimeStart} value={timeStart} />
            </div>

            <div className="mb-3 ml-5">
              <p className={classLable}>Ngày kết thúc</p>
              <DateTimePicker onChange={setTimeEnd} value={timeEnd} />
            </div>
          </div>

          {item?.ListJoined?.length > 0 && (
            <div className="w-full my-10">
              <p className="mb-5 font-bold">Danh sách thành viện tham gia</p>
              <table className="w-full border-[1px]">
                <tbody>
                  <tr className="w-full">
                    <th className="flex-1">STT</th>
                    <th className="flex-1">Tên hội viên</th>
                    <th className="flex-1">email</th>
                    <th className="flex-1">Số điện thoại</th>
                  </tr>
                  {item.ListJoined?.map((item: any, index: number) => (
                    <tr className="text-center border-[1px]">
                      <td className="flex-1 py-2">{index + 1}</td>
                      <td className="flex-1">{item?.name}</td>
                      <td className="flex-1">{item?.email}</td>
                      <td className="flex-1">{item?.phone}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mb-3">
            <div className={classLable}>Mô tả</div>
            <ReactQuill
              theme="snow"
              modules={MyEditormodules}
              formats={MyEditorformats}
              defaultValue={detailNews?.description?.replace(/&lt;/g, "<")}
              onChange={(txt) => {
                setValue("description", txt);
              }}
            />
            <div>
              {errors?.description ? (
                <text style={{ color: "red" }}>
                  {errors.description?.message}
                </text>
              ) : (
                <text className="h-2 bg-red-200 opacity-0">-</text>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              className="self-center px-8 py-2 mb-20 text-white rounded-md bg-primary"
            >
              {isUpdate ? "Cập nhật" : "Tạo mới"}
            </button>
          </div>
        </div>
        <ToastContainer />
      </LoadingWrap>
    );
};

export default AddEvent;
