import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { formatPrice } from "../../../utils/helper.ts";

function PaymentDetail() {
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const { item } = location.state || {};
  console.log("====itme===", item);
  // const { data: userInfo } = GetList({
  //   url: "users/get-user-by-id?userId=" + item?.userId,
  //   isLazy:false
  // });

  const onUpdate = async (id: number) => {
    setisLoading(true);
    const response = await request(
      `payment/update-payment?paymentId=${item?.id}`,
      {
        status: id,
      },
      "POST"
    );
    notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    if (response.status) {
      // notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
      navigate(-1);
    }
    setisLoading(false);
  };

  const notify = (title: string) => toast(title);

  return (
    <LoadingWrap active={isLoading}>
      <div>
        <h1 className="text-2xl font-bold text-center text-primary">
          CHI TIẾT HOÁ ĐƠN
        </h1>
        <div>
          <p className="font-semibold">Loại hoá đơn</p>

          <div className="px-2 py-2 border rounded-lg border-1">
            {item.type === "CLUB_FEE"
              ? "Lệ phí câu lạc bộ"
              : "Lệ phí tham gia sự kiện"}
          </div>
        </div>

        <div className="mt-5">
          <p className="font-semibold">Số tiền</p>
          <div className="px-2 py-2 border rounded-lg border-1">
            {formatPrice(item.amount)}
          </div>
        </div>

        <div className="mt-5">
          <p className="font-semibold">Ngày thanh toán</p>
          <div className="px-2 py-2 border rounded-lg border-1">
            {moment(item.payment_date).format("DD/MM/YYYY")}
          </div>
        </div>

        <div className="mt-5">
          <p className="font-semibold">Trạng thái</p>
          <div className="px-2 py-2 border rounded-lg border-1">
            {item?.status === 0
              ? "Chờ duyệt"
              : item?.status === 1
              ? "Đã duyệt"
              : "Đã huỷ"}
          </div>
        </div>

        <div className="mt-[20px]">
          <p className="font-semibold">Hình ảnh chuyển khoản</p>
          <div className="w-[200px] h-[400px] border-[1px] rounded-md">
            <img src={item?.image} className="object-contain w-full h-full " />
          </div>
        </div>

        <div className="flex justify-center mt-3">
          <button
            onClick={() => onUpdate(1)}
            className="px-5 py-2 mr-2 font-semibold text-white bg-green-500 rounded-lg"
          >
            Duyệt
          </button>
          <button
            onClick={() => onUpdate(2)}
            className="px-5 py-2 font-semibold text-white bg-red-500 rounded-lg"
          >
            Từ chối
          </button>
        </div>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
}

export default PaymentDetail;
