import moment from "moment";
import React, { useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";
import "./pagination.css";

interface PageChangeEvent {
  selected: number;
}
type PropsResponGetData = {
  data?: any | null;
  loading?: boolean;
  error?: Error | null;
  search?: (queryString: string) => void;
  loadMore?: (page: number) => void;
  reLoad?: () => void;
  totalCount?: any;
};

type TypeEvent = {
  address: string;
  description: string;
  endDate: string;
  fee_hv: number | null | undefined;
  fee_ko_hv: number | null | undefined;
  id: string;
  joined: number | null | undefined;
  people_size: number | null | undefined;
  startDate: string;
  status: number | null | undefined;
  title: string;
};

const ListEvent = () => {
  const [searchValue, setSearchValue] = useState("");
  const { data, search, loadMore, totalCount, reLoad }: PropsResponGetData =
    GetList<any>({
      url: "event/get-list-event",
      params: {
        search: searchValue,
      },
    });

  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const handlePageChange = ({ selected }: PageChangeEvent) => {
    loadMore(selected + 1);
  };
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onDelete = async (item: TypeEvent) => {
    try {
      setisLoading(true);
      const response = await request(
        "event/delete-event",
        { event_id: item.id },
        "POST"
      );
      setisLoading(false);
      notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
      reLoad();
    } catch (error) {
      notify("Đã có lỗi xảy ra, vui lòng thử lại sau");
      setisLoading(false);
    }
  };

  const notify = (title: string) => toast(title);

  const renderUser = (item: TypeEvent, index: number) => {
    return (
      <tr className="w-full py-2 text-center border">
        <td className="border">{index + 1}</td>
        <td className="border">{item.title}</td>
        <td className="border">{item.address}</td>
        <td className="border">
          {moment(item.startDate).format("DD/MM/YYYY HH:mm")}
          <p className="font-semibold text-primary">đến</p>
          {moment(item.endDate).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="border">
          HV: {item.fee_hv} <br /> Ko HV: {item.fee_ko_hv}
        </td>
        <td className="border">
          {item.joined}/{item.people_size}{" "}
        </td>
        <td className="border">
          Trạng thái:{" "}
          {item.status === 1
            ? "Sắp diễn ra"
            : item.status === 2
            ? "Đang diễn ra"
            : "Đã kết thúc"}{" "}
        </td>
        <td className="mx-2 my-2 border">
          <div
            onClick={() =>
              navigate("/admin/add-event", { state: { item: item } })
            }
            className="py-1 mx-2 bg-green-500 rounded-lg cursor-pointer"
          >
            Chi tiết
          </div>
          <div
            onClick={() => onDelete(item)}
            className="py-1 mx-2 mt-2 bg-red-500 rounded-lg cursor-pointer"
          >
            Xoá
          </div>
        </td>
      </tr>
    );
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (buttonRef.current) buttonRef.current.focus();
    }
  };
  return (
    <LoadingWrap active={isLoading}>
      <div>
        <div className="flex justify-center">
          <div
            className="
          flex 
          items-center 
          border-[1.5px] 
          w-[100%]
          md:w-[60%]
          rounded-lg
          pl-2
          border-primary
          "
          >
            <input
              className="flex min-w-[100px] px-2 py-2 outline-none w-full"
              placeholder="Tìm kiếm sự kiện"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={handleKeyPress}
            />
            <button
              onClick={() => search(searchValue)}
              className="h-full px-3 rounded-tr-lg rounded-br-lg bg-primary"
              ref={buttonRef}
            >
              <AiOutlineSearch size={26} />
            </button>
          </div>
        </div>
        <table className="w-full mt-[100px] text-center">
          <tbody>
            <tr className="font-semibold">
              <td className="min-w-[20px] border">STT</td>
              <td className="min-w-[100px] border">Tên sự kiện - giải đấu</td>
              <td className="min-w-[100px] border">Địa chỉ</td>
              <td className="min-w-[100px] border">Thời gian</td>
              <td className="min-w-[100px] border">Phí tham dự</td>
              <td className="min-w-[100px] border">Số lượng tham gia</td>
              <td className="min-w-[100px] border">Trạng thái</td>
              <td className="min-w-[100px] border">Thao tác</td>
            </tr>
            {data?.length > 0 && data?.map(renderUser)}
          </tbody>
        </table>
        {/* <div className="">{data?.length > 0 && data?.map(renderUser)}</div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 20,
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
          }}
        >
          <ReactPaginate
            activeClassName={"item active "}
            breakClassName={"item break-me "}
            breakLabel={"..."}
            containerClassName={"pagination"}
            disabledClassName={"disabled-page"}
            marginPagesDisplayed={2}
            nextClassName={"item next "}
            nextLabel={">"}
            onPageChange={handlePageChange}
            pageCount={totalCount / 20}
            pageClassName={"item pagination-page "}
            pageRangeDisplayed={2}
            previousClassName={"item previous"}
            previousLabel={"<"}
          />
        </div>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default ListEvent;
