import React, { useEffect, useState } from "react";
import { GetList } from "../../../hook/getList.tsx";
import Card from "./card.tsx";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { formatPrice } from "../../../utils/helper.ts";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import { request } from "../../../api/request.tsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { totalCount: totalEvent } = GetList<any>({
    url: "event/get-list-event",
  });

  const { totalCount: totalNews } = GetList<any>({ url: "news/get-list-news" });
  const { totalCount: totalUser } = GetList<any>({ url: "users/get-all-user" });

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [arrDay, setArrDay] = useState<Array<string>>([]);

  useEffect(() => {
    const dates: any = [];
    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      dates.push(moment(d).format("DD/MM/YYYY"));
    }
    setArrDay(dates);
  }, [startDate, endDate]);

  const { data: statistic } = GetList({
    url:
      "payment/statistic" +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD"),
    isLazy: false,
    dependencies: startDate,
    dependencies1: endDate,
  });
  const data = {
    labels: arrDay,

    datasets: [
      {
        label: "Tiền chi ra",
        data: statistic?.transactionsChi.map((item: any, index: number) => {
          return item.totalAmount;
        }),
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.6,
      },
      {
        label: "Tiền Thu vào",
        data: statistic?.transactionsThu.map((item: any, index: number) => {
          return item.totalAmount;
        }),
        fill: false,
        borderColor: "rgba(75,19,192,1)",
        tension: 0.1,
      },
    ],
  };

  // Các tuỳ chọn cho biểu đồ
  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: "top",
      },
      title: {
        // display: true,
        text: "Biểu đồ Line",
      },
    },
  };

  const Nhacdonghen = async()=>{
    const respone = await request("payment/push-noti-payment-fee",{},"POST")
    alert("Đã gửi thông báo đến người dùng")
  }

  return (
    <div>
      <div className="grid flex-wrap grid-cols-1 gap-2 px-2 md:grid-cols-2 lg:grid-cols-3">
        <Card lable="Người dùng" value={totalUser || 0} />
        <Card lable="Sự kiện - Giải đấu" value={totalEvent || 0} />
        <Card lable="Tin tức" value={totalNews || 0} />

        <Card
          lable="Tổng tiền thu"
          value={formatPrice(statistic?.tongThu || 0) || 0}
        />
        <Card
          lable="Tổng tiền chi"
          value={formatPrice(statistic?.tongChi || 0) || 0}
        />
        <Card
          lable="Tiền quỹ còn lại"
          value={formatPrice(statistic?.tongThu - statistic?.tongChi) || 0}
        />
      </div>

      
      <h1 className="mt-10 text-2xl font-bold text-primary">
        THỐNG KÊ DÒNG TIỀN
      </h1>

      <div className="flex gap-5 my-3 ">
        <div>
          <h5 className="">Ngày bắt đầu</h5>
          <DateTimePicker
            format={"dd-MM-y"}
            disableClock={true}
            onChange={setStartDate}
            value={startDate}
          />
        </div>

        <div>
          <h5 className="">Ngày kết thúc</h5>
          <DateTimePicker
            format={"dd-MM-y"}
            disableClock={true}
            onChange={setEndDate}
            value={endDate}
          />
        </div>
      </div>

      <button onClick={Nhacdonghen} className="px-5 py-2 text-white rounded-md bg-primary">Nhắc đóng phí</button>
      <Line data={data} options={options} />
    </div>
  );
};

export default Dashboard;
