import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";
import { ToastContainer, toast } from "react-toastify";

const MyEditormodules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const MyEditorformats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

interface IFormInput {
  description: string;
}
const validationSchemaRegister = Yup.object().shape({
  description: Yup.string().required("Vui lòng nhập nội quy câu lạc bộ"),
});

function ClubRule() {
  const { data: clubRule } = GetList({
    url: "rule/get-rule",
    params: { name: "CLUB_RULE" },
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      description: "",
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });
  const [loading, setloading] = useState<boolean>(false);
  const classLable = "font-semibold";
  const [clRule, setclRule] = useState("");

  useEffect(() => {
    if (clubRule?.length > 0) {
      const rule = clubRule.find((i) => {
        return i.name === "CLUB_RULE";
      });
      if (rule) {
        setclRule(rule.description?.replace(/&lt;/g, "<"));
        setValue("description",rule.description?.replace(/&lt;/g, "<"))
      }
    }
  }, [clubRule]);

  const notify = (title: string) => toast(title);

  const onSubmit = async (value: IFormInput) => {
    setloading(true);
    const respone = await request(
      "rule/update-rule",
      {
        name: "CLUB_RULE",
        description: value.description,
      },
      "POST"
    );
    setloading(false);
    notify(respone?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
  };
  if (clRule)
    return (
      <LoadingWrap active={loading}>
        <strong className="flex justify-center text-2xl text-primary">
          ĐIỀU LỆ CÂU LẠC BỘ
        </strong>
        <div className="mb-3">
          <div className={classLable}>Điều lệ câu lạc bộ</div>
          <ReactQuill
            theme="snow"
            modules={MyEditormodules}
            formats={MyEditorformats}
            defaultValue={clRule}
            onChange={(txt) => {
              setValue("description", txt);
            }}
          />
          <div>
            {errors?.description ? (
              <text style={{ color: "red" }}>
                {errors.description?.message}
              </text>
            ) : (
              <text className="h-2 bg-red-200 opacity-0">-</text>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleSubmit(onSubmit)}
            className="flex justify-center px-2 py-1 font-bold text-center text-white rounded-md bg-primary"
          >
            Submit
          </button>
        </div>
        <ToastContainer />
      </LoadingWrap>
    );
}

export default ClubRule;
