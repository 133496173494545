import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL, request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";

interface IFormInput {
  name: string;
  description: string;
  amount: number;
}

const validationSchemaRegister = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền tên người nhận"),
  description: Yup.string().required("Vui lòng nhập mô tả phiếu chi"),
  amount: Yup.number().required("Vui lòng nhập số tiền"),
});

const ThemPhieuChi = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const navigate = useNavigate();
  const { item } = location.state || {};
  useEffect(() => {
    if (item) {
      setisUpdate(true);
      setSelectedImage(item?.url);
    }
  }, [item]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: item?.receiver_name || "",
      amount: item?.amount || 0,
      description: item?.description,
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };

  const notify = (title: string) => toast(title);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    if (isUpdate) {
      onUpdate(data);
    } else {
      const body = {
        receiver_name: data.name,
        description: data.description,
        amount: data.amount,
      };
      console.log("======body====", body);
      const res = await request("payment/create-phieu-chi", body, "POST");
      notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
      setTimeout(() => {
        if (res?.status) {
          navigate(-1);
        }
      }, 500);
    }
    setLoading(false);
  };

  const onUpdate = async (data: IFormInput) => {
    setLoading(true);
    const body = {
      receiver_name: data.name,
      description: data.description,
      amount: data.amount,
    };
    console.log("======body====", body);
    const res = await request(
      `payment/update-phieu-chi?id=${item.id}`,
      body,
      "POST"
    );
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    setLoading(false);
    setTimeout(() => {
      if (res?.status) {
        navigate(-1);
      }
    }, 500);
  };

  const classInput = "w-full border-[1px] rounded-md px-2 py-2 outline-none";
  const classLable = "font-semibold";

  return (
    <LoadingWrap active={loading}>
      <div className="w-full">
        <div className="text-2xl font-bold text-center text-primary">
          {isUpdate ? "CẬP NHẬT PHIẾU CHI" : "THÊM PHIẾU CHI"}
        </div>
        <div className="mb-3">
          <div className={classLable}>Người nhận</div>
          <input
            onChange={(value) => handelOnchange(value, "name")}
            placeholder="Nhập tên người nhận"
            className={classInput}
            defaultValue={getValues("name")}
          />
          <div>
            {errors?.name ? (
              <p style={{ color: "red" }}>{errors.name?.message}</p>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <div className={classLable}>Số tiền</div>
          <input
            onChange={(value) => handelOnchange(value, "amount")}
            placeholder="Nhập số tiền chi"
            className={classInput}
            type="number"
            defaultValue={getValues("amount")}
          />
          <div>
            {errors?.amount ? (
              <text style={{ color: "red" }}>{errors.amount?.message}</text>
            ) : null}
          </div>
        </div>

        <div className="mb-3">
          <div className={classLable}>Mô tả</div>
          <input
            onChange={(value) => handelOnchange(value, "description")}
            placeholder="Nhập mô tả"
            className={classInput}
            defaultValue={getValues("description")}
          />
          <div>
            {errors?.description ? (
              <text style={{ color: "red" }}>
                {errors.description?.message}
              </text>
            ) : null}
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            className="self-center px-8 py-2 mb-20 text-white rounded-md bg-primary"
          >
            {isUpdate ? "Cập nhật" : "Tạo mới"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default ThemPhieuChi;
