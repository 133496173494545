import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import Compressor from "compressorjs";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";
import { ToastContainer, toast } from "react-toastify";
import './index.css';

const MyEditorformats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const validationSchemaRegister = Yup.object().shape({
  description: Yup.string().required("Vui lòng nhập nội quy câu lạc bộ"),
});

function CLBHistory() {
  const { data: clubRule } = GetList({
    url: "rule/get-rule",
    params: { name: "CLB_HISTORY" },
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      description: "",
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });
  const [loading, setloading] = useState<boolean>(false);
  const classLable = "font-semibold";
  const [clRule, setclRule] = useState("");
  const quillRef = useRef(null);

  useEffect(() => {
    if (clubRule?.length > 0) {
      const rule = clubRule.find((i) => {
        return i.name === "CLB_HISTORY";
      });
      if (rule) {
        setValue("description",rule.description?.replace(/&lt;/g, "<"))
        setclRule(rule.description?.replace(/&lt;/g, "<"));
      }
    }
  }, [clubRule]);

  const notify = (title: string) => toast(title);

  const handleImageUpload = (file) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 800,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          const base64data = reader.result;
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', base64data);
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      handleImageUpload(file);
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const onSubmit = async (value: IFormInput) => {
    setloading(true);
    const response = await request(
      "rule/update-rule",
      {
        name: "CLB_HISTORY",
        description: value.description,
      },
      "POST"
    );
    setloading(false);
    notify(response ?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
  };

  return (
    <LoadingWrap active={loading}>
      <strong className="flex justify-center text-2xl text-primary">
        LỊCH SỬ CLB
      </strong>
      <div className="mb-3">
        <div className={classLable}>Lịch sử CLB</div>
        {clRule && (
          <ReactQuill
            ref={quillRef}
            theme="snow"
            modules={modules}
            formats={MyEditorformats}
            defaultValue={clRule}
            onChange={(txt) => {
              setValue("description", txt);
            }}
          />
        )}
        <div>
          {errors?.description ? (
            <text style={{ color: "red" }}>
              {errors.description?.message}
            </text>
          ) : (
            <text className="h-2 bg-red-200 opacity-0">-</text>
          )}
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleSubmit(onSubmit)}
          className="flex justify-center px-2 py-1 font-bold text-center text-white rounded-md bg-primary"
        >
          Submit
        </button>
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
}

export default CLBHistory;