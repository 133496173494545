import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";
import moment from "moment";
import { formatPrice } from "../../../utils/helper.ts";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import * as XLSX from "xlsx";

interface PageChangeEvent {
  selected: number;
}

type PropsResponGetData = {
  data?: any | null;
  loading?: boolean;
  error?: Error | null;
  search?: (queryString: string) => void;
  loadMore?: (page: number) => void;
  reLoad?: () => void;
  totalCount?: any | undefined | null;
};

const TongChi = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data, totalCount, loadMore, search, reLoad }: PropsResponGetData =
    GetList<any>({
      url: "payment/get-all-phieu-chi",
      // isLazy: false,
    });

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  const { data: statistic } = GetList({
    url:
      "payment/statistic" +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD"),
    isLazy: false,
    dependencies: startDate,
    dependencies1: endDate,
  });

  const renderItems = (item: any, index: number) => {
    return (
      <tr key={index} className="border-[1px]">
        <td className="flex-1 py-2 text-center">{index + 1}</td>
        <td className="flex-1 text-center">
          {moment(item?.date).format("DD/MM/YYYY")}
        </td>
        <td className="flex-1 text-center">{item?.description}</td>
        <td className="flex-1 text-center">{formatPrice(item?.amount)}</td>
        <td className="flex-1 text-center">{item?.receiver_name}</td>
        <td className="flex-1 text-center">
          <button
            onClick={() => navigate("/admin/ThemPhieuChi", { state: { item } })}
            className="px-3 py-1 text-white rounded-md bg-primary"
          >
            Chi tiết
          </button>
        </td>
      </tr>
    );
  };

  const handlePageChange = ({ selected }: PageChangeEvent) => {
    loadMore(selected + 1);
  };

  const onExportFile = (): void => {
    const finalData = data?.map((item: any, index: number) => {
      return {
        STT: index + 1,
        "Số tiền": item?.amount,
        "Nội dung chi": item?.description,
        "Người nhận": item?.receiver_name,
        "Ngày thanh toán": moment(item?.date).format("DD/MM/YYYY"),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(finalData);
    // Định nghĩa style cho hàng cuối
    worksheet["A1"].s = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F213",
      },
    };
    // Tạo workbook mới và thêm worksheet vào workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Xuất workbook (Tải xuống file Excel)
    XLSX.writeFile(workbook, "export.xlsx");
  };

  return (
    <LoadingWrap active={loading}>
      <div className="flex items-center justify-between w-full">
        <div className="opacity-0">Thêm khoản chi</div>
        <div className="text-2xl font-bold text-center text-primary">
          {"BÁO CÁO KHOẢN CHI"}
        </div>

        <button
          onClick={() => navigate("/admin/ThemPhieuChi")}
          className="px-3 py-2 text-white rounded-lg bg-primary"
        >
          Thêm khoản chi
        </button>
      </div>

      <div>
        <p className="text-[20px] mt-10 font-medium">
          Tổng số tiền chi trong CLB:{" "}
          <p className="font-bold text-primary text-[25px]">
            {" "}
            {formatPrice(statistic?.tongChi) || 0}
          </p>
        </p>

        <button
          onClick={onExportFile}
          className="px-10 py-2 mt-5 text-white rounded-md bg-primary text-semibold"
        >
          Xuất file
        </button>

        <table className="w-full mt-5">
          <tbody className="border-[1px]">
            <tr className="">
              <th className="flex-1 py-2">STT</th>
              <th className="flex-1">Ngày tháng</th>
              <th className="flex-1">Nội dung chi</th>
              <th className="flex-1">Số tiền</th>
              <th className="flex-1">Người nhận</th>
              <th className="flex-1">Hành động</th>
            </tr>

            {data?.map(renderItems)}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 20,
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <ReactPaginate
          activeClassName={"item active"}
          breakClassName={"item break-me"}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          marginPagesDisplayed={2}
          nextClassName={"item next "}
          nextLabel={">"}
          onPageChange={handlePageChange}
          pageCount={totalCount / 20}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel={"<"}
        />
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default TongChi;
