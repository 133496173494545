import { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { GetList } from "../../../hook/getList.tsx";
import "./pagination.css";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { ToastContainer, toast } from "react-toastify";
import { formatPrice } from "../../../utils/helper.ts";
import * as XLSX from "xlsx";

interface PageChangeEvent {
  selected: number;
}

type PropsResponGetData = {
  data?: any | null;
  loading?: boolean;
  error?: Error | null;
  search?: (queryString: string) => void;
  loadMore?: (page: number) => void;
  reLoad?: () => void;
  totalCount?: any | undefined | null;
};

interface TypeBill {
  type: string;
  amount: number;
  user: any;
  payment_date: any;
  image: string;
  userId: string;
  status: number;
  id: string;
}

const ListPayment = () => {
  const { data, loadMore, search, reLoad, totalCount }: PropsResponGetData =
    GetList<any>({
      url: "payment/admin-get-list-payment",
      params: { limit: 20 },
      // isLazy: true
    });

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  const { data: statistic } = GetList({
    url:
      "payment/statistic" +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD"),
    isLazy: false,
    dependencies: startDate,
    dependencies1: endDate,
  });

  const [loading, setLoading] = useState(false);
  const searchRef = useRef<HTMLButtonElement>(null);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = ({ selected }: PageChangeEvent) => {
    loadMore(selected + 1);
  };

  const navigate = useNavigate();

  const notify = (title: string) => toast(title);

  const onUpdate = async (id: string) => {
    setLoading(true);
    const response = await request(
      `payment/update-payment?paymentId=${id}`,
      {
        status: 1,
      },
      "POST"
    );
    notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    if (response.status) {
      reLoad();
    }
    setLoading(false);
  };

  const renderUser = (item: TypeBill, index: number) => {
    if (index === 3) {
      console.log("=====item===", item);
    }
    const diff = moment(item.payment_date)
      .add(1, "year")
      .diff(moment(), "months");
    return (
      <tr className="w-full py-2 text-center border">
        <td className="flex-1 border">{index + 1}</td>
        <td className="border">
          {item.type === "CLUB_FEE"
            ? "Lệ phí câu lạc bộ"
            : item.type === "FUND"
            ? "Tiền tài trợ"
            : "Lệ phí tham gia sự kiện"}
        </td>
        <td className="flex-1 border">{formatPrice(item.amount)}</td>
        <td className="flex-1 border">{item?.user?.name || "-"}</td>
        <td className="flex-1 border">
          {moment(item.payment_date).format("DD/MM/YYYY")}
        </td>

        <td
          style={{ backgroundColor: diff <= 1 ? "red" : "white" }}
          className="flex-1 border"
        >
          {item.type === "CLUB_FEE"
            ? moment(item.payment_date).add(1, "years").format("DD/MM/YYYY")
            : "-"}
        </td>

        <td className="flex-1 border">
          {item.status === 0
            ? "Chờ duyệt"
            : item.status === 1
            ? "Đã duyệt"
            : "Từ chối duyệt"}
        </td>

        <td className="w-[200px]">
          <button
            onClick={() =>
              navigate("/admin/payment-detail", { state: { item } })
            }
            className="px-2 mx-2 font-semibold text-white bg-green-500 rounded-md"
          >
            Chi tiết
          </button>
          {item.status !== 1 && (
            <button
              onClick={() => onUpdate(item.id)}
              className="px-2 mx-2 my-2 text-white bg-red-500 rounded-md first-line:font-semibold"
            >
              {"Duyệt"}
            </button>
          )}
        </td>
      </tr>
    );
  };

  const onExportFile = (): void => {
    const finalData = data?.map((item: any, index: number) => {
      return {
        STT: index + 1,
        "Số tiền": item?.amount,
        "Loại hoá đơn":
          item.type === "CLUB_FEE"
            ? "Lệ phí câu lạc bộ"
            : item.type === "FUND"
            ? "Tiền tài trợ"
            : "Lệ phí tham gia sự kiện",
        "Người nộp tiền": item?.user?.name || "-",
        "Ngày thanh toán": moment(item?.payment_date).format("DD/MM/YYYY"),
        "Trạng thái":
          item.status === 0
            ? "Chờ duyệt"
            : item.status === 1
            ? "Đã duyệt"
            : "Từ chối duyệt",
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(finalData);
    // Định nghĩa style cho hàng cuối
    worksheet["A1"].s = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F213",
      },
    };
    // Tạo workbook mới và thêm worksheet vào workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Xuất workbook (Tải xuống file Excel)
    XLSX.writeFile(workbook, "export.xlsx");
  };

  return (
    <LoadingWrap active={loading}>
      {/* <div className="flex justify-center">
        <div
          className="
        flex 
        items-center 
        border-[1.5px] 
        w-[50%]
        rounded-lg
        pl-2
        border-primary
        "
        >
          <input
            className="flex w-full px-2 py-2 outline-none "
            placeholder="Tìm kiếm người dùng"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <button
            onClick={() => search(searchValue)}
            ref={searchRef}
            className="h-full px-3 rounded-tr-lg rounded-br-lg bg-primary"
          >
            <AiOutlineSearch size={26} />
          </button>
        </div>
      </div> */}
      <p className="text-2xl font-semibold text-center text-primary">
        DÒNG TIỀN THU VÀO
      </p>

      <p className="text-[20px] mt-10 font-medium">
        Tổng số tiền thu vào:{" "}
        <p className="font-bold text-primary text-[25px]">
          {" "}
          {formatPrice(statistic?.tongThu)}
        </p>
      </p>

      <button
        onClick={onExportFile}
        className="px-10 py-2 mt-5 text-white rounded-md bg-primary text-semibold"
      >
        Xuất file
      </button>

      <div className="min-h-screen">
        <table className="w-full mt-[10px] text-center">
          <tbody>
            <tr className="font-semibold">
              <td className="min-w-[20px] border">STT</td>
              <td className="min-w-[100px] border">Loại hoá đơn</td>
              <td className="min-w-[100px] border">Số tiền</td>
              <td className="min-w-[100px] border">Người nộp tiền</td>
              <td className="min-w-[100px] border">Ngày thanh toán</td>
              <td className="min-w-[100px] border">Lần thanh toán tiếp theo</td>
              <td className="min-w-[100px] border">Trạng thái</td>
              <td className="min-w-[50px] border w-fit">...</td>
            </tr>
            {data?.length > 0 && data?.map(renderUser)}
          </tbody>
        </table>
      </div>
      {/* <div>{data?.length > 0 && data?.map(renderUser)}</div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 20,
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <ReactPaginate
          activeClassName={"item active"}
          breakClassName={"item break-me"}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          marginPagesDisplayed={2}
          nextClassName={"item next "}
          nextLabel={">"}
          onPageChange={handlePageChange}
          pageCount={totalCount / 20}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel={"<"}
        />
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default ListPayment;
