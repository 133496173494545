"use client";
import { useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { GetList } from "../../../hook/getList.tsx";
import "./pagination.css";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { ToastContainer, toast } from "react-toastify";

interface PageChangeEvent {
  selected: number;
}

type PropsResponGetData = {
  data?: any | null;
  loading?: boolean;
  error?: Error | null;
  search?: (queryString: string) => void;
  loadMore?: (page: number) => void;
  reLoad?: () => void;
  totalCount?: any | undefined | null;
};

interface TypeUser {
  name: string;
  phone: string;
  email: string;
  avatar: string;
  vga: number;
  birthDay: Date;
  business_areas: string;
  address: string;
  size_uniform: string;
  isConfirm?: boolean;
  id: string;
  isBlock: boolean;
}

const CustomerBirthday = () => {
  const { data, loadMore, search, reLoad, totalCount }: PropsResponGetData =
    GetList<any>({
      url: "users/get-user-birthday-curent-month",
      params: { limit: 20 },
      // isLazy: true
    });

    console.log("=====data====",data)

  const [loading, setLoading] = useState(false);
  const searchRef = useRef<HTMLButtonElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const handlePageChange = ({ selected }: PageChangeEvent) => {
    loadMore(selected + 1);
  };

  const navigate = useNavigate();

  const notify = (title: string) => toast(title);

  const onActiveAccount = async (id: string) => {
    setLoading(true);
    const response = await request(
      "users/admin-updateUser",
      {
        isConfirm: true,
        userId: id,
      },
      "POST"
    );
    notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    if (response.status) {
      reLoad();
    }
    setLoading(false);
  };

  const onBlockAccount = async (id: string) => {
    setLoading(true);
    const response = await request(
      "users/admin-updateUser",
      {
        isBlock: true,
        userId: id,
      },
      "POST"
    );
    notify(
      response?.message
        ? "Đã khoá người dùng"
        : "Đã có lỗi xảy ra, vui lòng thử lại sau"
    );
    if (response.status) {
      reLoad();
    }
    setLoading(false);
  };

  const onUnLockAccount = async (id: string) => {
    setLoading(true);
    const response = await request(
      "users/admin-updateUser",
      {
        isBlock: false,
        userId: id,
      },
      "POST"
    );
    notify(
      response?.message
        ? "Đã mở khoá người dùng"
        : "Đã có lỗi xảy ra, vui lòng thử lại sau"
    );
    if (response.status) {
      reLoad();
    }
    setLoading(false);
  };

  const onResetPassword = async (item: any) => {
    setLoading(true)
    const respone = await request(
      "auth/forgot-password",
      {
        email: item?.email,
        password: "123456",
      },
      "POST"
    );
    setLoading(false)
    notify(respone?.message)
  };

  const renderUser = (item: TypeUser, index: number) => {
    return (
      <tr className="w-full py-2 text-center border">
        <td className="border">{index + 1}</td>
        <td className="border">{item.name}</td>
        <td className="border">{item.phone}</td>
        <td className="border">{item.email}</td>
        <td className="border">{item.vga}</td>
        <td className="border">{moment(item.birthDay).format("DD/MM/YYYY")}</td>
        <td className="border">{item.size_uniform}</td>
        <td className="mx-2 my-2 border">{item.business_areas}</td>
        <td className="mx-2 my-2 border">
          <div className="font-bold">
            {item.isConfirm ? (
              <p className="text-green-500">Đã kích hoạt</p>
            ) : (
              <p className="text-red-500">Chưa kích hoạt</p>
            )}
          </div>
          {!item.isConfirm && (
            <button
              onClick={() => onActiveAccount(item.id)}
              className="px-2 py-1 text-white bg-green-500 rounded-lg"
            >
              Kích hoạt
            </button>
          )}
        </td>
        <td>
          <div className="flex flex-col py-1">
            <button
              onClick={() => navigate("/admin/editUser", { state: { item } })}
              className="px-2 mx-2 font-semibold text-white bg-green-500 rounded-md"
            >
              Chi tiết
            </button>
            <button
              onClick={() =>
                item?.isBlock
                  ? onUnLockAccount(item.id)
                  : onBlockAccount(item.id)
              }
              className="px-2 mx-2 mt-2 font-semibold text-white bg-red-500 rounded-md"
            >
              {!item.isBlock ? "Khoá" : "Mở khoá"}
            </button>

            <button
              onClick={() => onResetPassword(item)}
              className="px-2 mx-2 mt-2 font-semibold text-white rounded-md bg-primary"
            >
              {"Đặt lại mật khẩu"}
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (searchRef.current) searchRef.current.focus();
    }
  };

  return (
    <LoadingWrap active={loading}>
      {/* <div className="flex justify-center">
        <div
          className="
        flex 
        items-center 
        border-[1.5px] 
        w-[50%]
        rounded-lg
        pl-2
        border-primary
        "
        >
          <input
            className="flex w-full px-2 py-2 outline-none "
            placeholder="Tìm kiếm người dùng"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <button
            onClick={() => search(searchValue)}
            ref={searchRef}
            className="h-full px-3 rounded-tr-lg rounded-br-lg bg-primary"
          >
            <AiOutlineSearch size={26} />
          </button>
        </div>
      </div> */}

      <table className="w-full mt-[100px] text-center">
        <tbody>
          <tr className="font-semibold">
            <td className="min-w-[20px] border">STT</td>
            <td className="min-w-[100px] border">Tên</td>
            <td className="min-w-[100px] border">SĐT</td>
            <td className="min-w-[100px] border">Email</td>
            <td className="min-w-[100px] border">VGA</td>
            <td className="min-w-[100px] border">Sinh nhật</td>
            <td className="min-w-[100px] border">Size áo</td>
            <td className="min-w-[100px] border">Lĩnh vực kinh doanh</td>
            <td className="min-w-[100px] border">Trạng thái kích hoạt</td>
            <td className="min-w-[100px] border">Hành động</td>
          </tr>
          {data?.length > 0 && data?.map(renderUser)}
        </tbody>
      </table>
      {/* <div>{data?.length > 0 && data?.map(renderUser)}</div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 20,
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <ReactPaginate
          activeClassName={"item active"}
          breakClassName={"item break-me"}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          marginPagesDisplayed={2}
          nextClassName={"item next "}
          nextLabel={">"}
          onPageChange={handlePageChange}
          pageCount={totalCount / 20}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel={"<"}
        />
      </div>
      <ToastContainer />
    </LoadingWrap>
  );
};

export default CustomerBirthday;
